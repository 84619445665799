// TODO: deprecated, pefer to use ~/components/v3/Carousel
import { Button, Icon } from '@lemonenergy/lemonpie'
import type { PropsWithChildren } from 'react'
import React, { useState, useEffect, useRef, Children } from 'react'
import styled, { css } from 'styled-components'

import scrollbarDefaultStyles from '../SharedStyles/scrollbarDefaultStyles'

export const carouselFallbackCss = `
div.carousel-container__scrollbar {
    overflow-x: auto;
    padding-bottom: 16px;
}

div.carousel-container__scrollbar ~ div {
    display: none;
}
`

export const CarouselContainer = styled.div(
  ({ theme: { spacing } }) => css`
    display: flex;
    align-items: stretch;
    gap: ${spacing.sm};
    overflow-x: hidden;
    width: 100%;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;

    ${scrollbarDefaultStyles}

    // Gutters for the first and last child. Can be easily customized by targeting the corresponding className.
    .carousel-first-item {
      padding-left: ${spacing.sm};
    }

    .carousel-last-item {
      padding-right: ${spacing.sm};
    }
  `,
)

const CarouselWrapper = styled.div(
  ({ theme: { spacing } }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${spacing.sm};
    width: 100%;
  `,
)

const CarouselItem = styled.div(
  () => css`
    display: flex;
    align-items: stretch;
    width: 75%;
    flex-shrink: 0;
    scroll-snap-align: center;
  `,
)

const elementQueryKeys = {
  next: 'nextElementSibling',
  previous: 'previousElementSibling',
} as const

type ElementQueryKeys = keyof typeof elementQueryKeys

const CarouselNavigator = styled.div(
  ({ theme: { spacing, fontSize, colors } }) => {
    return css`
      display: flex;
      gap: ${spacing(2.5)};

      ${Button} {
        min-width: auto;
        padding: 0;
        border: none;

        &:disabled {
          ${Icon} {
            color: ${colors.neutral.main};
          }
        }

        ${Icon} {
          &,
          > svg {
            transition: color 0.15s;
            font-size: ${fontSize(2.5)};
            width: ${fontSize(2.5)};
            height: ${fontSize(2.5)};
          }
        }
      }
    `
  },
)

const scrollToView = (target: HTMLElement) => {
  target.scrollIntoView({ block: 'nearest', inline: 'center' })
}

const Carousel = ({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) => {
  const [unfocusButton, setUnfocusButton] = useState<null | ElementQueryKeys>(
    'previous',
  )
  const carouselContainerRef = useRef<HTMLDivElement | null>(null)
  const currentTargetOnFocusRef = useRef<HTMLDivElement | null>(null)

  const updateFocus = (element: HTMLElement) => {
    if (!element.nextElementSibling) {
      setUnfocusButton('next')
    }

    if (!element.previousElementSibling) {
      setUnfocusButton('previous')
    }

    if (element.nextElementSibling && element.previousElementSibling) {
      setUnfocusButton(null)
    }
  }

  const onItemClick: React.MouseEventHandler<HTMLDivElement> = e => {
    scrollToView(e.currentTarget)
    currentTargetOnFocusRef.current = e.currentTarget

    updateFocus(e.currentTarget)
  }

  const scrollToElement = (key: ElementQueryKeys) => {
    const siblingElement =
      currentTargetOnFocusRef.current?.[elementQueryKeys[key]]

    if (siblingElement && siblingElement instanceof HTMLDivElement) {
      // Reason for this requestAnimationFrame: https://github.com/facebook/react/issues/20770
      // setState cancels scrollToView (wonderful bug)
      requestAnimationFrame(() => scrollToView(siblingElement))
      currentTargetOnFocusRef.current = siblingElement

      updateFocus(siblingElement)
    }
  }

  useEffect(() => {
    const initialElement = carouselContainerRef.current?.firstElementChild
    if (initialElement && initialElement instanceof HTMLDivElement) {
      currentTargetOnFocusRef.current = initialElement
    }
  }, [])

  return (
    <CarouselWrapper className={className}>
      <CarouselContainer
        ref={carouselContainerRef}
        className="carousel-container__scrollbar"
      >
        {Children.map(children, (child, index) => {
          const isFirst = index === 0
          const isLast = index === Children.count(children) - 1

          let itemClassName: undefined | string

          if (isFirst) {
            itemClassName = 'carousel-first-item'
          } else if (isLast) {
            itemClassName = 'carousel-last-item'
          }

          return (
            <CarouselItem onClick={onItemClick} className={itemClassName}>
              {child}
            </CarouselItem>
          )
        })}
      </CarouselContainer>

      <CarouselNavigator>
        <Button
          color="neutral"
          onClick={() => scrollToElement('previous')}
          disabled={unfocusButton === 'previous'}
        >
          <Icon name="arrow-left" />
        </Button>

        <Button
          color="neutral"
          onClick={() => scrollToElement('next')}
          disabled={unfocusButton === 'next'}
        >
          <Icon name="arrow-right" />
        </Button>
      </CarouselNavigator>
    </CarouselWrapper>
  )
}

export default Carousel
